<template>
  <div>
    <b-breadcrumb class="bg-transparent" :items="items"></b-breadcrumb>
    <b-col md="5" lg="3">
      <div>
        <b-alert show variant="success" v-if="showSuccess">Updated</b-alert>
      </div>

      <b-form @submit="onSubmit" novalidate>
        <b-form-group id="input-group-3" label="Email:" label-for="input-1">
          <b-form-input
            type="email"
            v-model="email"
            placeholder="Enter Email"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.email">{{ errors.email }} </b-alert>
          <b-alert show variant="warning" v-if="userAlreadyExists">{{ userAlreadyExists }} </b-alert>
        </b-form-group>

        <b-form-group id="input-group-3" label="Password:" label-for="input-2">
          <b-form-input
            type="password"
            v-model="password"
            placeholder="Enter Password"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.password">{{ errors.password }}</b-alert>
        </b-form-group>

        <b-form-group id="input-group-3" label="Confirm Password:" label-for="input-2">
          <b-form-input
            type="password"
            v-model="confirm_password"
            placeholder="Enter Confirm Password"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</b-alert>
        </b-form-group>

        <b-form-group id="input-group-3" label="First Name:" label-for="input-3">
          <b-form-input
            v-model="first_name"
            placeholder="Enter First Name"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.firstName">{{ errors.firstName }}</b-alert>
        </b-form-group>


        <b-form-group id="input-group-2" label="Last Name:" label-for="input-4">
          <b-form-input
            v-model="last_name"
            placeholder="Enter Last Name"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.lastName">{{ errors.lastName }}</b-alert>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>



    </b-col>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
        userAlreadyExists: false,
      items: [
        {
          text: 'Users',
          href: '/users'
        },
        {
          text: this.$route.params.uuid
            ? this.$route.params.uuid === JSON.parse(localStorage.getItem('user')).uuid
              ? 'Profile' : 'Edit User'
            : 'Create User',
          active: true
        }],
      errors: {email: null, password: null, confirmPassword: null, firstName: null, lastName: null},
      originalEmail: '',
      isAdmin: '',
      showSuccess: false,
      message: ''
    }
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.getUser(this.$route.params.uuid).then((response) => {
        this.originalEmail = response.data.email
        this.email = response.data.email
        this.password = '********'
        this.confirm_password = '********'
        this.first_name = response.data.first_name
        this.last_name = response.data.last_name
      })
    }
    this.isAdminUser()

  },
  computed: {
    user() {
      return this.$store.getters.getUserState
    },
  },
  methods: {
    onSubmit(event) {
        event.preventDefault()

        this.errors = {};

        if (this.email === '') {
            this.errors.email = 'Email is required.';
        } else if (!this.validEmail(this.email)) {
            this.errors.email = 'Email is not valid.';
        }
        else {

            this.getUserByEmail({email: this.email, exclude: this.originalEmail}).then((response) => {

                if (response.data.exists == true) {
                    this.userAlreadyExists = 'Email already exists.';
                    this.errors.email = ''
                }
            })
                .catch(error => {
                    this.userAlreadyExists = error.response.data.error
                    this.errors.email = ''

                })
        }

        if (this.password === '') {
            this.errors.password = 'Password is required.';
        }

        if (!this.$route.params.uuid || (this.$route.params.uuid && this.password !== '********')) {
            if (!this.validatePassword(this.password)) {
                this.errors.password = 'At least 1 lowercase, 1 uppercase, 1 number, 1 symbol ' +
                    'and a minimum of 8 chars.';
            }
        }

        if (this.confirm_password === '') {
            this.errors.confirmPassword = 'Confirm Password is required.';
        }

        if (this.confirm_password !== this.password) {
            this.errors.confirmPassword = 'Password has to be the same as confirm password.';
        }

        if (this.first_name === '') {
            this.errors.firstName = 'First Name is required.';
        }

        if (this.last_name === '') {
            this.errors.lastName = 'Last Name is required.';
        }

        if (this.is_admin === '') {
            this.errors.isAdmin = 'Is Admin is required.';
        }

                  if (Object.keys(this.errors).length > 0) {
        return false;
      }

      if (!this.$route.params.uuid) {
        this.createUser(
          {
            email: this.email,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
          }
        ).then(() => {
          this.$router.push('/users')
        }).catch(error => {
          const response = error.response.data.error

          if (response.email) {
            this.errors.email = response.email
          }
           if (response.password) {
            this.errors.password = response.password
          }
           if (response.first_name) {
            this.errors.first_name = response.first_name
          }
           if (response.last_name) {
            this.errors.last_name = response.last_name
          }
        })
      } else {
        const userData = {
          uuid: this.$route.params.uuid,
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
        };

        if (this.password !== '********') {
          userData.password = this.password;
        }

        this.updateUser(userData);


         this.showSuccess = true

          setTimeout(() => this.showSuccess = false, 3000)
      }
    },
    ...mapActions(['createUser', 'updateUser', 'getUser', 'getUserByEmail']),
    ...mapGetters(['getUserState']),
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validatePassword(password) {
      return /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password) && password.length > 7;
    },
    isAdminUser() {
      this.isAdmin = JSON.parse(localStorage.getItem('user')).is_admin
    },

  },
  watch: {
    user() {
     /* const user = this.$store.getters.getUserState
      console.debug('is admin ' + user.is_admin)
console.log('user')
console.log(user)
      this.originalEmail = user.email
      this.email = user.email
      this.password = '********'
      this.confirm_password = '********'
      this.first_name = user.first_name
      this.last_name = user.last_name*/
    },
  },
}
</script>

<style>
.breadcrumb {
  background-color: #d1ecf1;
}
</style>
